<template>
  <div>
    <query-form labelWidth="110px" class="content_block" ref="queryForm" @search="handleSearch" @reset="handleReset">
      <el-form-item label="公司名称：" >
        <el-input placeholder="请输入公司名称" v-model.trim="query.name"></el-input>
      </el-form-item>
      <el-form-item label="申请人：" >
        <el-input placeholder="请输入申请人" v-model.trim="query.applicantName"></el-input>
      </el-form-item>
      <el-form-item label="试用状态：" >
        <el-select v-model="query.trialStatus" @change="handleSearch">
          <el-option v-for="i in tenantStatusEnumList" :label="i.desc" :key="i.name" :value="i.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户来源：" >
        <el-select v-model="query.salesSource" @change="handleSearch">
          <el-option v-for="i in tenantSourceEnumList" :key="i.name" :label="i.desc" :value="i.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="试用产品：" >
        <product-selector v-model="query.trialProductId" :name.sync="query.trialProductName" @change="handleSearch"></product-selector>
      </el-form-item>
      <el-form-item label="推荐人来源：" >
        <el-select v-model="query.referrerSource" @change="handleSearch">
          <el-option v-for="i in referrerSourceList" :key="i.value" :label="i.label" :value="i.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推广渠道：" >
        <el-input placeholder="请输入推广渠道" v-model.trim="query.channelName"></el-input>
        <!-- <el-select v-model="query.channelName" @change="handleSearch">
          <el-option v-for="i in channelNameList" :key="i.value" :label="i.label" :value="i.value"></el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item label="推荐人：" >
        <el-input placeholder="请输入推荐人" v-model.trim="query.referrerName"></el-input>
      </el-form-item>
      <el-form-item label="推广渠道：" >
        <el-input placeholder="请输入推广渠道" v-model.trim="query.channelName"></el-input>
        <!-- <el-select v-model="query.channelName" @change="handleSearch">
          <el-option v-for="i in channelNameList" :key="i.value" :label="i.label" :value="i.value"></el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item label="试用到期日：" >
         <el-date-picker
          v-model="trialExpiry"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="申请日期：" >
         <el-date-picker
          v-model="trialActive"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="最近登录日：" >
        <el-date-picker
          type="daterange"
          value-format="yyyy-MM-dd"
          v-model="lastLogin"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
    </query-form>
    <table-with-pagination :tableData="tenantList"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="createTimeSortInfo"
      @size-change="getTableList"
      @current-change="getTableList"
      @sort-change="getTableList"
      :leftToolbar="tableButtons"
    />
    <el-dialog title="激活租户" width="500px" :visible.sync="dialogVisible" >
      <el-form label-width="100px">
        <el-form-item label="试用周期" >
          <el-select v-model="activeForm.trialPeriod">
            <el-option label="7天" :value="7"></el-option>
            <el-option label="14天" :value="14"></el-option>
            <el-option label="30天" :value="30"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="超管账号">
          {{activeForm.adminAccount}}
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleActive">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import TenantApi from '@/api/tenant';
import { wrapWithAll } from '@/utils/Enum';
import ProductSelector from '@/components/productSelector';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import TenantSourceEnum, { TenantSourceEnumList } from '@/model/TenantSourceEnum';
import TenantStatusEnum, { TenantStatusEnumList } from '@/model/TenantStatusEnum';
import TenantCompanyTypeEnum, { TenantCompanyTypeEnumList } from '@/model/TenantCompanyTypeEnum';
export default {
  name: 'TENANT_INDEX',
  mixins: [
    TablePaginationMixin,
  ],
  components: {
    ProductSelector,
  },
  data () {
    return {
      tenantStatusEnumList: wrapWithAll(TenantStatusEnumList, ''),
      tenantSourceEnumList: wrapWithAll(TenantSourceEnumList, ''),
      query: {
        applicantName: '',
        name: '',
        salesSource: '',
        trialProductId: '',
        trialProductName: '',
        trialStatus: '',
        referrerName: '',
        referrerSource: '',
        isFormal: false,
        channelName: '',
      },
      trialActive: [],
      trialExpiry: [],
      lastLogin: [],
      tableButtons: [
        {
          name: '新增试用',
          status: 'primary',
          size: 'small',
          code: 'addNewEquipment',
          icon: 'vxe-icon--plus',
          permission: 'saas_trialTenantManage:add',
          click: ()=> {
            this.$router.push({
              name: 'TENANT_ADD',
            });
          },
        },
      ],
      tenantList: [],
      referrerSourceList: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '后台',
          value: 'bs',
        },
        {
          label: 'PC端',
          value: 'pc',
        },
        {
          label: 'H5',
          value: 'h5',
        },
      ],
      channelNameList: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '柯渡采购部',
          value: '柯渡采购部',
        },
      ],
      columnList: [
        {
          tooltip: true,
          label: '试用产品',
          minWidth: '100px',
          prop: 'trialProductName',
        },
        {
          tooltip: true,
          label: '公司名称',
          minWidth: '150px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '公司类型',
          minWidth: '150px',
          prop: 'type',
          format: val=> TenantCompanyTypeEnumList[TenantCompanyTypeEnum[val]] ? TenantCompanyTypeEnumList[TenantCompanyTypeEnum[val]].desc : '',
        },
        {
          tooltip: true,
          label: '申请人姓名',
          minWidth: '150px',
          prop: 'applicantName',
        },
        {
          tooltip: true,
          label: '最近登录时间',
          minWidth: '150px',
          prop: 'lastLoginTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '申请日期',
          minWidth: '150px',
          prop: 'createTime',
          sortable: true,
          format: val=>this.$options.filters['date-format'](val, 'YYYY-MM-DD'),
        },
        {
          tooltip: true,
          label: '试用到期日期',
          minWidth: '150px',
          prop: 'trialExpiryTime',
          format: val=>this.$options.filters['date-format'](val, 'YYYY-MM-DD'),
        },
        {
          tooltip: true,
          label: '客户来源',
          minWidth: '150px',
          prop: 'salesSource',
          format: val=> TenantSourceEnumList[TenantSourceEnum[val]].desc,
        },
        {
          tooltip: true,
          label: '试用状态',
          minWidth: '150px',
          prop: 'trialStatus',
          format: val=> TenantStatusEnumList[TenantStatusEnum[val]].desc,
        },
        {
          tooltip: true,
          label: '推荐人来源',
          minWidth: '150px',
          prop: 'referrerSource',
        },
        {
          tooltip: true,
          label: '推广渠道',
          minWidth: '150px',
          prop: 'channelName',
        },
        {
          tooltip: true,
          label: '推荐人',
          minWidth: '150px',
          prop: 'referrerName',
        },
        {
          label: '操作',
          minWidth: '200px',
          fixed: 'right',
          render: (h, {row}) => {
            let actions = [];
            actions.push(<span v-auth={'saas_trialTenantManage:query'} class='cursor-pointer text-primary margin-left' onClick={this.handleActionClick.bind(this, row, 'check')}>查看</span>);
            if (row.trialStatus === 'wait' || row.trialStatus === 'waitActive') {
              actions.push(<span v-auth={'saas_trialTenantManage:check'} class='cursor-pointer text-primary margin-left' onClick={this.handleActionClick.bind(this, row, 'approve')}>审核</span>);
            }
            if (row.trialStatus === 'close' || row.trialStatus === 'expire') {
              actions.push(<span v-auth={'saas_trialTenantManage:active'} class='cursor-pointer text-primary margin-left' onClick={this.handleActionClick.bind(this, row, 'active')}>激活</span>);
            }
            if (row.trialStatus === 'run') {
              actions.push(<span v-auth={'saas_trialTenantManage:close'} class='cursor-pointer text-primary margin-left' onClick={this.handleActionClick.bind(this, row, 'close')}>关闭</span>);
            }
            return <div>{...actions}</div>;
          },
        },
      ],
      dialogVisible: false,
      activeTrialPeriod: '7',
      activeTenantId: '',
      activeForm: {
        id: '',
        trialPeriod: '',

      },
    };
  },
  methods: {
    handleActionClick ({id: tenantId, adminAccount}, action) {
      if (action === 'check') {
        this.$router.push({
          name: 'TENANT_DETAIL',
          query: {
            id: tenantId,
          },
        });
      } else if (action === 'approve') {
        this.$router.push({
          name: 'TENANT_APPROVE',
          query: {
            id: tenantId,
          },
        });
      } else if (action === 'active') {
        this.dialogVisible = true;
        this.activeForm.id = tenantId;
        this.activeForm.trialPeriod = 7;
        this.activeForm.adminAccount = adminAccount;
      } else if (action === 'close') {
        this.$router.push({
          name: 'TENANT_APPROVE',
          query: {
            id: tenantId,
          },
        });
      } else if (action === 'bind') {
        TenantApi.bind(tenantId).then(()=>{
          this.$message.success('推送成功');
          this.handleSearch();
        });
      }
    },
    handleActive () {
      TenantApi.active({...this.activeForm}).then(()=>{
        this.dialogVisible = false;
        this.handleSearch();
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        applicantName: '',
        name: '',
        salesSource: '',
        trialProductId: '',
        trialProductName: '',
        trialStatus: '',
        isFormal: false,
        channelName: '',
      };
      this.trialActive = [];
      this.trialExpiry = [];
      this.lastLogin = [];
      this.handleSearch();
    },
    getTableList () {
      let param = {...this.query, ...this.createTimeSortInfo, pageSize: this.pagination.pageSize, pageNum: this.pagination.currentPage};
      if (this.trialActive && this.trialActive.length > 1) {
        param.createStartTime = this.trialActive[0];
        param.createEndTime = this.trialActive[1];
      }
      if (this.trialExpiry && this.trialExpiry.length > 0) {
        param.trialExpiryStartTime = this.trialExpiry[0];
        param.trialExpiryEndTime = this.trialExpiry[1];
      }
      if (this.lastLogin && this.lastLogin.length > 0) {
        param.lastLoginStartTime = this.lastLogin[0];
        param.lastLoginEndTime = this.lastLogin[1];
      }
      TenantApi.getTenantList(param).then(res => {
        this.tenantList = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
  },
};
</script>

